@use "../global" as *;
.l-header {
  background-color: $white;
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: $headerHeight;
    margin: 0 auto;
    padding: 0 20px 0 0;
    position: relative;
    z-index: 100;
    border-top: 3px solid $keyColor;
    box-shadow: $box-shadow;
    @include mq-max($changePoint) {
      display: block;
      height: $headerHeight-sp;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }

  &__logo {
    height: 108px;
    width: 235px;
    display: flex;
    align-items: center;
    background-color: $white;
    padding: 0 70px 0 35px;
    clip-path: polygon(0 0, 100% 0, calc(100% - 65px) 100%, 0% 100%);
    & > img {
      width: 130px;
      opacity: 1;
      transition: opacity $transition;
      &:hover {
        img {
          opacity: .7;
        }
      }
    }
    &Wrapper {
      position: absolute;
      top: -3px;
      left: 0;
      z-index: 1;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
    }
  }

  &__modules {
    position: relative;
    height: 100%;
    @include mq-max($changePoint) {
      height: 100%;
      padding: 10px;
      background-color: $white;
      line-height: 0;
      display: flex;
      align-items: center;
    }
  }
}
