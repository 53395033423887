@use "../global" as *;
.l-navigation {
//  background-color: $white;
  height: 100%;
  @include mq-max($changePoint) {
    display: none;
    height: auto;
    //用途不明　不要ならそのうち削除
    html.open &.active > ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    }
  }
  //用途不明　不要ならそのうち削除
  &.active {
    transform: translateY(0%);
  }

  //ハンバーガーメニューオープン時に追加されるクラス
  &.is-navOpen {}

  &__inner {
    padding: 0;
    display: flex;
    height: 100%;//menu.jsで指定した値が優先される
    @include mq-max($changePoint) {
      display: block;
      height: calc(100vh - #{$headerHeight-sp}); //画面高さ - fixedヘッダーの高さ
      overflow-y: scroll;
    }
  }
  &__item {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    &:last-child {
      margin-right: 0;
    }
    @include mq-max($changePoint) {
      display: block;
      height: auto;
      margin-right: 0;
      border-top: 1px solid $gray;
      &:last-child{
        border-bottom: 1px solid $gray;
      }
    }
    a {
      @include mq-max($changePoint) {
        background-color: $white;
      }
    }
    &>a {
      text-decoration: none;
      color: $textColor;
      font-size: 15px;
      display: inline-block;
      // display: flex;
      // align-items: center;
      // height: 100%;
      padding: 10px 12.5px;
      @include mq-max($changePoint) {
        display: block;
        padding: 15px 10px;
      }
      &:hover {
        color: $keyColor;
      }
    }

    //下層メニュー
    &.has-dropdown {
      & > a {
        position: relative;
        &::after {
          @include fa('f107');
          position: relative;
          margin-left: 8px;
          bottom: 0;
          transition: all $transition;
          @include mq-max($changePoint) {
            position: absolute;
            @include fa('f067');
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
          }
        }
        @media (any-hover: hover) {
          &:hover {
            &::after {
              bottom: -5px;
            }
          }
        }
      }

      .l-dropDown {
        position: absolute;
        top: $headerHeight - 33px; // headerの高さ - 33px
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        overflow: hidden;
        background-color: $white;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          left: 0;
        }
        @include mq-max($changePoint) {
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
        }
        li {
          border-bottom: 1px solid $gray;
          @include mq-max($changePoint) {
            border-bottom: none;
          }
          &:last-child {
            border: none;
          }
          a {
            background-color: $white;
            padding: 10px 15px;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $textColor;
            width: 100%;
            @include mq-max($changePoint) {
              line-height: 0;
              height: 0;
              padding: 0 0 0 25px;
              opacity: 0;
              transition: height $transition,
                          opacity 0.4s,
                          padding $transition,
                          line-height $transition;
            }
          }
        }
      }

      // クリック時
      &.isDropdownOpen {
        & > a {
          &::after {
            @include mq-max($changePoint) {
              content: '\f068';
            }
          }
        }
        .l-dropDown {
          top: $headerHeight; // headerの高さ
          opacity: 1;
          visibility: visible;
          @include mq-max($changePoint) {
            top: auto;
            opacity: 1;
            display: block;
          }
          &>li {
            border-bottom: 1px solid $gray;
            &:first-child {
              border-top: 1px solid $gray;
            }
            &:last-child {
              border-bottom: none;
            }
          }
          a {
            transition: color $transition,
                        height $transition;
            &:hover {
              color: $keyColor;
            }
            @include mq-max($changePoint) {
              opacity: 1;
              line-height: 1.5;
              height: 54px;
              padding: 15px 10px 15px 25px;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  &__btn {
    display: inline-block;
    background-color: #242424 !important;
    color: $white;
    font-size: 15px;
    padding: 11px 35px 11px 20px;
    border-radius: 4px;
    position: relative;
    &::after {
      content: "";
      background-image: url("/inc/image/common/ico/ico_navBtn.svg");
      background-size: cover;
      width: 29px;
      height: 100%;
      position: absolute;
      top: 0;
      right: 2px;
    }
    &Wrapper {
      padding: 0 10px;
      @include mq-max($changePoint) {
        background-color: $white;
        padding: 15px 10px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
