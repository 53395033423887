@use "../global" as *;
.c-cvArea {
  background: $keyColor;
  border-top: 4px solid $yellow;
  &__inner {
    width: 100%;
    max-width: $contentWidth;
    margin-inline: auto;
    padding: 75px 0 105px;
    @include mq-max(content) {
      padding: 60px 30px;
    }
    @include mq-max(sm) {
      padding: 60px 20px;
    }
    @include mq-max(xs) {
      padding: 60px 15px;
    }
  }
  &__contents {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
    @include mq-max(sm) {
      flex-direction: column;
      gap: 40px;
    }
  }
  &__text {
  }
  &__head {
    font-size: 49px;
    line-height: 1.2;
    &Sub {
      display: block;
      font-size: 14px;
    }
  }
}
.c-cvArrow {
  width: 100px;
  border-radius: 9999px;
  border: 1px solid $white;
  color: $white;
  @include mq-max(content) {
    width: 80px;
  }
  &__inner {
    position: relative;
    padding-top: 100%;
    height: 0;
    & > i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      transition: all $transition;
    }
  }
  &__link {
    @media (any-hover: hover) {
      &:hover {
        & i {
          left: calc(50% + 6px);
        }
      }
    }
  }
}