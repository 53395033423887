@use "sass:math";
@use "../global" as *;


.c-picture {
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
  }

  img {
    @extend %imgFormat;
    height: auto;
  }


  //Mod
  &--round {
    overflow: hidden;
    border-radius: 5px;
  }
  &--shadow {
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  }
}