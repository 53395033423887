@use "../global" as *;

$footerChangePoint: md;
.l-f {
  background-color:$white;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 75px 0 110px;
    display: flex;
    flex-direction: column;
    @include mq-max(content) {
      padding: 50px 20px;
    }
  }
  &__contents {
    display: flex;
    justify-content: space-between;
    @include mq-max($footerChangePoint) {
      flex-direction: column;
      gap: 50px;
      align-items: center;
    }
  }
  &-info {
    display: block;
  }
  &-logo {
    display: block;
    width: 200px;
    margin-bottom: 30px;
    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include mq-max($footerChangePoint) {
      width: 300px;
      margin-inline: auto;
    }
    @include mq-max(xs) {
      width: 200px;
    }
  }
  &-nav {
    display: flex;
    gap: 55px;
    justify-content: flex-end;
    @include mq-max($footerChangePoint) {
      justify-content: flex-start;
      gap: 50px;
    }
    @include mq-max(sm) {
      flex-wrap: wrap;
      gap: 0;
      margin: 0 -15px -40px;
    }
  }
  &-navColumn {
    @include mq-max(sm) {
      width: calc(100% / 3);
      padding: 0 15px 40px;
    }
    @include mq-max(xs) {
      width: 50%;
    }
  }
  &-navLink {
    margin-bottom: 20px;
    @include mq-max(sm) {
      margin-bottom: 15px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    & > a {
      color: $textColor;
      font-weight: 600;
      font-size: 15px;
    }

    &--hasLower {
      & > span {
        font-size: 15px;
        display: block;
        color: $textColor;
        font-weight: 600;
        margin-bottom: 15px;
        @include mq-max(sm) {
          margin-bottom: 10px;
        }
      }
    }
    &__lowerList {

    }
    &__lowerItem {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      & > a {
        display: inline-block;
        padding-left: 15px;
        color: $textColor;
        font-size: 15px;
        font-weight: 600;
        position: relative;
        &::before {
          @include fa('f054');
          font-size: 10px;
          line-height: 1;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          transition: all $transition;
        }
        @media (any-hover: hover) {
          &:hover {
            &::before {
              left: 5px;
            }
          }
        }
      }
    }
  }

  // フッター下部
  &__bottom {
    height: 66px;
    background-color: $black;
    &Inner {
      width: 100%;
      max-width: $contentWidth;
      margin-inline: auto;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include addSectionSidePadding
    }
  }

  // 個人情報保護方針
  &__privacy {
    color: $white;
    font-size: 11px;
  }

  //Copyright
  &__copyright {
    color: $white;
    font-size: 12px;
  }
}
